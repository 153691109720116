import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40')
];

export const server_loads = [0,2];

export const dictionary = {
		"/(authed)": [6,[2]],
		"/api/document": [31],
		"/api/events/[id]/instance": [32],
		"/api/flow": [33],
		"/api/pings": [34],
		"/api/question": [35],
		"/api/space": [36],
		"/api/team": [37],
		"/api/team/[id]/custom-fields": [38],
		"/(authed)/calendar": [13,[2]],
		"/(authed)/documents": [14,[2]],
		"/(authed)/flows": [15,[2]],
		"/join": [~39],
		"/login": [40],
		"/(authed)/logs": [16,[2]],
		"/(authed)/onboarding": [17,[2]],
		"/(authed)/questions": [18,[2]],
		"/(authed)/settings": [19,[2,5]],
		"/(authed)/settings/billing": [20,[2,5]],
		"/(authed)/settings/custom-fields": [21,[2,5]],
		"/(authed)/settings/integrations": [22,[2,5]],
		"/(authed)/settings/labels": [23,[2,5]],
		"/(authed)/settings/members": [24,[2,5]],
		"/(authed)/settings/profile": [25,[2,5]],
		"/(authed)/settings/profile/appearance": [~26,[2,5]],
		"/(authed)/settings/profile/danger": [27,[2,5]],
		"/(authed)/settings/profile/notifications": [~28,[2,5]],
		"/(authed)/teams": [29,[2]],
		"/(authed)/time": [30,[2]],
		"/(authed)/[space]": [7,[2,3]],
		"/(authed)/[space]/calendar": [8,[2,3]],
		"/(authed)/[space]/calendar/[event]": [~9,[2,3,4]],
		"/(authed)/[space]/documents": [10,[2,3]],
		"/(authed)/[space]/documents/[document]": [11,[2,3]],
		"/(authed)/[space]/questions": [12,[2,3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';